import { localStorageKeys } from '@/constants/local-storage';
import queryKeys from '@/constants/query-keys';
import gameData from '@/data/app-2/game';
import { DeepPartial } from '@/types/deep-partial';
import { Progress } from '@/types/progress';
import { enableShortcuts } from '@/utils/env';
import i18n from '@/utils/i18n';
import { queryClient } from '@/utils/query-client';
import request from '@/utils/request';
import updateProgressQuery from '@/utils/update-progress-query';

const DEFAULT_APP_ID = gameData.id;

type SendEventResponse = {
  progress?: Progress;
  success: boolean;
  error?: { error: string } | string;
  infos?: { request_identifier: string };
};

class ProgressService {
  get(appId: string = DEFAULT_APP_ID) {
    return request<Progress>(`/progress/${appId}`);
  }

  /**
   * Delete the progress of the current app
   */
  async delete(appId: string = DEFAULT_APP_ID) {
    await request(`/progress/${appId}`, { method: 'DELETE' });

    const keys = Object.values(localStorageKeys[appId as keyof typeof localStorageKeys]);
    keys.forEach((key) => localStorage.removeItem(key));

    sessionStorage.clear();
  }

  /**
   * Main method
   */
  async update(appId: string, data: DeepPartial<Progress>) {
    updateProgressQuery(appId, data);

    return request(`/progress/${appId}`, { method: 'PUT', data });
  }

  /**
   * Mark a quizz as completed
   */
  async completeQuizz(boxId: number) {
    await this.update(DEFAULT_APP_ID, { quizzes: { [boxId]: true } });

    updateProgressQuery(DEFAULT_APP_ID, { quizzes: { [boxId]: true } });
  }


  async sendEvent(appId: string, data: {
    id: string;
    answer?: string | string[]
  }) {
    if (enableShortcuts) {
      console.debug('🚀 ~ ProgressService ~ sendEvent ~ data:', data);
    }

    const { progress, success, error, infos } = await request<SendEventResponse>(`/progress/${appId}/event`, { method: 'POST', data });

    if (enableShortcuts) {
      console.debug('🚀 ~ ProgressService ~ sendEvent ~', { progress, success, error, infos });
    }

    if (progress) {
      queryClient.setQueryData<Progress>(
        queryKeys.progress(appId, localStorage.getItem(localStorageKeys.userId)),
        () => progress
      );
    }

    return {
      success,
      // @ts-expect-error error.error is a translation key
      error: error ? i18n.t(typeof error === 'string' ? error : error.error) as string : undefined,
      infos,
    }
  }
}

export const progressService = new ProgressService();
